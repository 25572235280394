import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { CaracteristicaDto } from '@crc/sgr-typescript-client';
import { IonSelect } from '@ionic/angular';
import { ActionEnum } from '@shared/enums';


@Component({
  selector: 'app-feature-list-form',
  templateUrl: './feature-list-form.component.html',
})
export class FeatureListFormComponent {
  @Input() featuresFG!: FormGroup;
  @Input() caracteristicas!: CaracteristicaDto[];
  @Output() selectedfeature=new EventEmitter();
  protected get featuresFA(): FormArray {
    return this.featuresFG.controls.caracteristicasSolicitudes as FormArray;
  }
  protected getSelectFormControl(index: number): FormControl {
    return (this.featuresFA.get(index.toString()) as FormGroup)?.controls.idValor as FormControl;
  }

  clear(event: IonSelect) {
    event.value = null;
  }
  test()
  {
    this.selectedfeature.emit(ActionEnum.Search);
  }
}
