import { TiposRecursosClienteDto } from "@crc/sgr-typescript-client";
import * as fromTypeResourcesClient from '../actions/typeResourceClient.action';
import { Action, createReducer, on } from '@ngrx/store';

export interface TypeResourcesClientState {
    typeResourceClient: TiposRecursosClienteDto[];
    loaded: boolean;
    loading: boolean;
}

export const initialState: TypeResourcesClientState = {
    typeResourceClient: [],
    loaded: false,
    loading: false,
};

const _reducer = createReducer(
    initialState,
    on(fromTypeResourcesClient.loadTypeResourcesClient, state => ({
        ...state,
        loading: true
    })),
    on(
        fromTypeResourcesClient.loadTypeResourcesClientSuccess,
        (state, { typeResourceClient }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            typeResourceClient,
        };
    }),
    on(fromTypeResourcesClient.loadTypeResourcesClientFailure, state => ({
        ...state,
        loading: false,
        loaded: false,
    })),
);

export const reducer = (state: TypeResourcesClientState | undefined, action: Action) => {
    return _reducer(state, action);
}
  
export const getTypeResourcesClient = (state: TypeResourcesClientState) => state.typeResourceClient;
export const getTypeResourcesClientLoading = (state: TypeResourcesClientState) => state.loading;
export const getTypeResourcesClientLoaded = (state: TypeResourcesClientState) => state.loaded;