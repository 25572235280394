import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TiposRecursosPersonaAppDto } from '@crc/sgr-typescript-client';
import { CurrentGrantsService, LangService, ResourceSelectorService } from '@shared/services/front';
import { PartialObserver } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface TabFormat {
  label: string;
  router: string;
  permission?: "nivelPermisoSolicitudes" | "nivelPermisoPrereservas";
  isActive?: boolean;
  icon: string;
}
@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
  protected tabs: TabFormat[] = [];
  protected dataJSON!: any;
  protected get isSmallScreen(): boolean {
    return window.innerWidth < 345;
  }
  private tiposRecursoPersona!: TiposRecursosPersonaAppDto;
  private allTabs!: TabFormat[];
  constructor(public langService: LangService, private rselector: ResourceSelectorService, private currentGrantService: CurrentGrantsService, private route: ActivatedRoute) {
    this.langService.changeLang().subscribe({
      next: (data: any) => {
        this.dataJSON = data;
        this.allTabs = [
          { label: this.dataJSON.TABS.Home, router: "/dashboard", icon: "home" },
          { label: this.dataJSON.TABS.Requests, router: "/dashboard/request", permission: "nivelPermisoSolicitudes", icon: "document" },
          { label: this.dataJSON.TABS.PreBookings, router: "/dashboard/pre-booking", permission: "nivelPermisoPrereservas", icon: "document-text" },
          { label: this.dataJSON.TABS.Bookings, router: "/dashboard/booking", icon: "flag" }
        ];
        this.ngOnInit();
      }
    });
  }
  ngOnInit(): void {
    this.updateTabs();
    let observer: PartialObserver<any> = {
      next: () => this.updateTabs()
    };
    this.rselector.onChange().subscribe(observer);
    this.route.params.subscribe(observer);
  }
  private updateTabs(): void {
    this.currentGrantService.getByPersonaActual().pipe(
      filter(data => data !== null)
    ).subscribe({
      next: (tiposRecurso:TiposRecursosPersonaAppDto) => {
        this.tiposRecursoPersona = tiposRecurso
        this.tabs = this.allTabs
          .filter(x => !x.permission || (this.tiposRecursoPersona[x.permission]))
          .map(x => { return { ...x, isActive: x.router.split("/").length > 2 ? window.location.pathname.startsWith(x.router) : x.router == window.location.pathname } });
      },
    });
  }
}
