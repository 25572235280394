import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LangService } from './lang.service';

declare const require: any;
const Swal = require('sweetalert2');

export interface SweetOptions {
  title: string;
  icon: "warning" | "success" | "error";
  showCancelButton?: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  reverseButtons?: boolean;
}
@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {
  protected dataJSON!: any;
  mySubject!: Subject<boolean>;
  constructor(public langService: LangService){
    this.langService.changeLang().subscribe({
			next: (data : any) => {
				this.dataJSON = data;
			}
		});
  }
  showAlert(textTitle: string, textConfirmed: string): Observable<boolean> {
    return this.showGeneral(textTitle, textConfirmed, true);
  }

  showAlertOneDialog(textTitle: string): Observable<boolean> {
    return this.showGeneral(textTitle, '', false);
  }
  showGeneralAsync(options: SweetOptions, response: Observable<SweetOptions>, cancelOptions?: SweetOptions): Observable<boolean> {
    return new Observable<boolean>(e => {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          cancelButton: 'btn btn-danger',
          confirmButton: 'btn btn-success'
        },
        heightAuto: false,
        buttonsStyling: true
      });
      swalWithBootstrapButtons.fire(options).then((result: any) => {
        if(result.isConfirmed) {
          e.next(true);
          e.complete();
          let responseOptions: SweetOptions;
          response.subscribe({
            next: (response: SweetOptions) => responseOptions = response,
            complete: () => swalWithBootstrapButtons.fire(responseOptions)
          });
        } else {
          e.next(false);
          e.complete();
          if(cancelOptions) swalWithBootstrapButtons.fire(cancelOptions);
        }
      });
    });
  }
  private showGeneral(textTitle: string, textConfirmed: string, showSecondDialog: boolean) {
    this.mySubject = new Subject<boolean>();

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        cancelButton: 'btn btn-danger',
        confirmButton: 'btn btn-success'
      },
      heightAuto: false,
      buttonsStyling: true
    });

    swalWithBootstrapButtons.fire({
      title: textTitle,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.dataJSON.ALERT.Yes,
      cancelButtonText: this.dataJSON.ALERT.No,
      reverseButtons: false
    }).then((result: any) => {
      if (result.isConfirmed) {
        if (showSecondDialog) {
          swalWithBootstrapButtons.fire(
            this.dataJSON.ALERT.deleted,
            textConfirmed,
            'success'
          );
        }
        this.mySubject.next(true);
      } else if (
        result.dismiss === Swal.DismissReason.cancel) {
        if (showSecondDialog) {
          swalWithBootstrapButtons.fire(
            this.dataJSON.ALERT.cancelled,
            this.dataJSON.ALERT.notDone,
            this.dataJSON.ALERT.error
          );
        }
        this.mySubject.next(false);
      }
      else {
        this.mySubject.next(false);
      }
    });
    return this.mySubject;
  }
}