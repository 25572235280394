import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from '@shared/services/front/loader.service';
import { finalize } from 'rxjs/operators';
import * as fromRootStore from '../store';
import { Store } from '@ngrx/store';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(private store: Store<fromRootStore.State>, private loaderService: LoaderService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.store.dispatch(fromRootStore.showSpinner());
    this.loaderService.show();
    return next.handle(request).pipe(
        finalize(() => {
          this.loaderService.hide();
          this.store.dispatch(fromRootStore.hideSpinner())
        })
    );
  }
}
