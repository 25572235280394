import { AuthConfig } from 'angular-oauth2-oidc';

export const authConfig: AuthConfig = {
  issuer: 'https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_XEIpK8zoE',
  clientId: '4tu5ateg0dk7b3rrpvtvav2krv', // The "Auth Code + PKCE" client
  responseType: 'token',
  redirectUri: window.location.origin + '/',
  scope: 'openid profile email sgr-rs/read.write aws.cognito.signin.user.admin', // Ask offline_access to support refresh token refreshes
  useSilentRefresh: false, // Needed for Code Flow to suggest using iframe-based refreshes

  showDebugInformation: true, // Also requires enabling "Verbose" level in devtools

  strictDiscoveryDocumentValidation: false
};
