import { Directive, ContentChildren, QueryList, ElementRef, AfterViewInit, Input, Renderer2 } from '@angular/core';
import { IonItem, IonCol, IonItemSliding } from '@ionic/angular';


@Directive({
  selector: '[appAnimateItems]'
})
export class AnimateItemsDirective implements AfterViewInit {
  @Input() className!: string;

  private observer!: IntersectionObserver;

  @ContentChildren(IonCol, {read: ElementRef}) itemsColumn!: QueryList<ElementRef>;
  @ContentChildren(IonItem, {read: ElementRef}) items!: QueryList<ElementRef>;
  @ContentChildren(IonItemSliding, {read: ElementRef}) itemSlidings!: QueryList<ElementRef>;
  itemIndex = [0, 0, 0];
  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(){
    let options = {
      threshold: 0.5
    };
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry: any) => {
        if(entry.isIntersecting){
          this.renderer.addClass(entry.target, this.className);
        }
      })
    }, options);
    let childrens: QueryList<ElementRef<any>>[] = [this.itemsColumn, this.items, this.itemSlidings];
    childrens.forEach((c, i) => {
      this.update(c);
      c.changes.subscribe({
        next: (d: QueryList<ElementRef<any>>) => {
          if(!d.get(0) || !d.get(0)!.nativeElement.className.includes(this.className)) this.itemIndex[i] = 0;
          this.update(d, this.itemIndex[i]);
          this.itemIndex[i] = d.length;
        }
      });
    });
  };
  update(items: QueryList<ElementRef<any>>, lastIndex?: number) {
    lastIndex = lastIndex ?? 0;
    items.forEach((item, index, arr) =>{
      let realIndex = index-lastIndex!-1;
      if(realIndex > -2) setTimeout(() =>{
        this.observer.observe(item.nativeElement);
      }, realIndex > 15 ? 0 : realIndex* 200);
    });
  }
}
