import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TipoRecursoListDto } from '@crc/sgr-typescript-client';
import { ResourceSelectorService } from '@shared/services/front';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromStore from '@shared/store';
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';

@Component({
  selector: 'app-resourceselector',
  templateUrl: './resourceselector.component.html',
  styleUrls: ['./resourceselector.component.scss'],
})
export class ResourceselectorComponent implements OnInit {

  @Input() typeResources$! : Observable<TipoRecursoListDto[]>;
  @Input() cancelarButton!: boolean;
  @Output() onSave = new EventEmitter();
  protected typeResources! : TipoRecursoListDto[]
  
  protected detailFG = new FormGroup({
    selected : new FormControl(0, Validators.required),
  })
  protected get canEdit(): boolean {
    return this.selectorService.canEdit;
  }
  protected get isHighlighted(): boolean {
    if(JSON.parse(JSON.stringify(localStorage.getItem("highlight"))) == 1) return true;
    else return false;
  }
  protected selectedResource?: TipoRecursoListDto;
  constructor(private store: Store<fromStore.MainState>, protected selectorService: ResourceSelectorService, private location : Location) {}
  selected!: TipoRecursoListDto;
  ngOnInit() {
    this.store.select(fromStore.getTypeResources).pipe(
      filter((data: TipoRecursoListDto[])  => data !== null && data.length > 0)
    ).subscribe({
      next: (x: TipoRecursoListDto[]) => {
        this.typeResources = x;
        this.selectorService.onChange().subscribe({
          next: (selected: TipoRecursoListDto) => {
            this.selectedResource = selected;
            this.detailFG.patchValue({selected: this.selectedResource?.idTipoRecurso})
            if(this.selectedResource == undefined){
              if(!this.selectorService.hasResource){
                this.changed();
              } 
            }
          }
          
        });
      }
    });
  }
  cancelar() {
    this.location.back();
  }
  protected changed(): void {
    if(this.detailFG.controls.selected.value && this.detailFG.controls.selected.value != 0){
      this.store.select(fromStore.getTypeResourceById(this.detailFG.controls.selected.value)).subscribe({
        next: (x: TipoRecursoListDto) => {
          this.selectorService.setResource(x);
        }
      });
    }
    else if(!this.selectorService.hasResource) {
      this.store.select(fromStore.getTypeResources).subscribe({
        next: (x: TipoRecursoListDto[])  => {
          this.selectorService.setResource(x[0]);
        }
      });
    }
    
  }
  save(){
    this.onSave.emit();
  }
}
