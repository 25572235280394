import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BlobService {

  b64toBlob(b64Data : string) {
    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: 'image/jpeg'});
    return blob;
  }

  BlobTob64(blob : File | Blob) : any{
    let reader = new FileReader();
    return new Observable((subscriber: any)=>{
      reader.onload = function(event) {
        let base64String = reader.result;
        subscriber.next(base64String?.toString().substring(base64String.toString().indexOf(',') + 1)!)
        subscriber.complete();
      }
      reader.readAsDataURL(blob);
  })
  }

}
