export { DatesFormComponent } from "./forms/dates-form/dates-form.component";
export { FeatureListFormComponent } from "./forms/feature-list-form/feature-list-form.component";
export { RequiredFeaturesFormComponent } from "./forms/required-features-form/required-features-form.component";
export { LoadingComponent } from "./loading/loading.component";
export { MapDiagramComponent } from "./map-diagram/map-diagram.component";
export { ResourceComponent } from "./resource/resource.component";
export { ResourceselectorComponent } from "./resourceselector/resourceselector.component";
export { SidemenuComponent } from "./sidemenu/sidemenu.component";
export { SubheaderComponent } from "./subheader/subheader.component";
export { TabsComponent } from "./tabs/tabs.component";


import { DatesFormComponent } from "./forms/dates-form/dates-form.component";
import { FeatureListFormComponent } from "./forms/feature-list-form/feature-list-form.component";
import { RequiredFeaturesFormComponent } from "./forms/required-features-form/required-features-form.component";
import { LoadingComponent } from "./loading/loading.component";
import { MapDiagramComponent } from "./map-diagram/map-diagram.component";
import { ResourceComponent } from "./resource/resource.component";
import { ResourceselectorComponent } from "./resourceselector/resourceselector.component";
import { SidemenuComponent } from "./sidemenu/sidemenu.component";
import { SubheaderComponent } from "./subheader/subheader.component";
import { TabsComponent } from "./tabs/tabs.component";

export const components: any[] = [
    DatesFormComponent,
    FeatureListFormComponent,
    RequiredFeaturesFormComponent,
    LoadingComponent,
    ResourceComponent,
    ResourceselectorComponent,
    SidemenuComponent,
    SubheaderComponent,
    TabsComponent,
    MapDiagramComponent
]