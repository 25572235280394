import { TiposRecursosPersonaAppDto } from '@crc/sgr-typescript-client';
import { createAction, props } from '@ngrx/store';

export const loadTypeResourcesGrants = createAction('[TiposRecursosPersonaAppDto] Load TypeResourcesGrants');

export const loadTypeResourcesGrantsSuccess = createAction(
  '[TiposRecursosPersonaAppDto] Load TypeResourcesGrants Success',
  props<{ typeResourcesGrants: TiposRecursosPersonaAppDto[] }>()
);

export const loadTypeResourcesGrantsFailure = createAction(
  '[TiposRecursosPersonaAppDto] Load TypeResourcesGrants Failure',
  props<{ error: any }>()
);

export const clearTypeResourcesGrants = createAction('[TiposRecursosPersonaAppDto] Clear TypeResourcesGrants');
