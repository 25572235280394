import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatNativeDateModule, } from '@angular/material/core';
import * as componentindex from "./components/";
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AnimateItemsDirective } from 'src/app/directives/animate-items.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SwiperModule } from 'swiper/angular';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import { HeaderInterceptor, LoaderInterceptor, ErrorInterceptor } from '../interceptors';
import {MatCardModule} from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { effects, reducers } from './store';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SwiperModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatCardModule,
    TranslateModule,
    StoreModule.forFeature('main', reducers, {}),
    EffectsModule.forFeature(effects)
  ],
 declarations:[
  AnimateItemsDirective,
  ...componentindex.components

  ],
  exports:[
    AnimateItemsDirective, IonicModule, CommonModule, RouterModule, FormsModule, SwiperModule, ReactiveFormsModule, TranslateModule,
    ...componentindex.components

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ]
})
export class SharedModule {}