import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class JsonUtilsService {
    exactStringify(object: Object): string {
        return JSON.stringify(object, (key, value) => {
            if(!Object.isExtensible(value)) return value;
            let keys = Object.keys(value).sort();
            let json: any = {};
            keys.forEach(x => json[x] = value[x]);
            return json;
        });
    }
}