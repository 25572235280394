import { Component, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { CaracteristicaDto } from '@crc/sgr-typescript-client';

@Component({
  selector: 'app-required-features-form',
  templateUrl: './required-features-form.component.html',
})
export class RequiredFeaturesFormComponent {
  @Input() featuresFG!: FormGroup;
  @Input() caracteristicas!: CaracteristicaDto[];
  protected get featuresFA(): FormArray {
    return this.featuresFG.controls.caracteristicasSolicitudes as FormArray;
  }
  protected getSelectFormControl(index: number): FormControl {
    return (this.featuresFA.get(index.toString()) as FormGroup).controls.obligatorio as FormControl;
  }
  protected getSelectFormValue(index: number): string | null {
    if(!this.caracteristicas) return null;
    let fg = (this.featuresFA.get(index.toString()) as FormGroup).controls.idValor as FormControl;
    return this.caracteristicas[index].valoresCaracteristicas?.find(x => x.idValor == parseInt(fg.value))?.valor || null;
  }
}
