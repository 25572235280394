import { FeaturesEffects } from './features.effect';
import { TypeResourcesEffects } from './typeResources.effect';
import { TypeResourcesGrantsEffects } from './typeResourceGrants.effect';
import { TypeResourcesClientEffects } from './typeResourceClient.effect';

export const effects: any[] = [
    FeaturesEffects,
    TypeResourcesEffects,
    TypeResourcesGrantsEffects,
    TypeResourcesClientEffects
  ];

export * from './features.effect';
export * from './typeResources.effect';
export * from './typeResourceGrants.effect';
export * from './typeResourceClient.effect';
