import { Injectable } from '@angular/core';
import { AppPermisosService, AppPersonaService, CreatedItemResult, PersonaAppRUDto, PersonaAppUDto, TiposRecursoClienteDatoAccesoAppDto, TiposRecursosPersonaAppDto } from '@crc/sgr-typescript-client';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PersonService {

  constructor(private person: AppPersonaService, private permissions: AppPermisosService) { }

  getLang(): Observable<PersonaAppRUDto>{ 
      return this.person.apiAppPersonaIdiomaGet();
  }
  putLang(person: PersonaAppRUDto): Observable<CreatedItemResult>{
    return this.person.apiAppPersonaIdiomaPut(person);
  }
  getConfiguration(): Observable<TiposRecursosPersonaAppDto[]> {
    return this.permissions.apiAppTiposRecursoPersonaGet();
  }
  putConfiguration(permissions: PersonaAppUDto): Observable<CreatedItemResult> {
    return this.permissions.apiAppTiposRecursoConfiguracionPut(permissions);
  }
  getAccesoDato(idTipoRecurso: number): Observable<TiposRecursoClienteDatoAccesoAppDto[]> {
    return this.person.apiAppPersonaAccesoDatoIdTipoRecursoGet(idTipoRecurso);
  }
  putAccesoDato(idTipoRecurso: number, identifiers: TiposRecursoClienteDatoAccesoAppDto[]): Observable<CreatedItemResult> {
    identifiers.map((id: TiposRecursoClienteDatoAccesoAppDto) => ({...id, observaciones: id.observaciones != null ? id.observaciones : id.observaciones = ''}));
    return this.person.apiAppPersonaAccesoDatoIdTipoRecursoPut(idTipoRecurso, identifiers);
  }
}