import { TipoRecursoListDto } from "@crc/sgr-typescript-client";
import * as fromTypeResources from '../actions/typeResources.action';
import { Action, createReducer, on } from '@ngrx/store';

export interface TypeResourcesState {
    typeResources: TipoRecursoListDto[];
    loaded: boolean;
    loading: boolean;
}

export const initialState: TypeResourcesState = {
    typeResources: [],
    loaded: false,
    loading: false,
};

const _reducer = createReducer(
    initialState,
    on(fromTypeResources.loadTypeResources, state => ({
        ...state,
        loading: true
    })),
    on(
        fromTypeResources.loadTypeResourcesSuccess,
        (state, { typeResources }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            typeResources,
        };
    }),
    on(fromTypeResources.loadTypeResourcesFailure, state => ({
        ...state,
        loading: false,
        loaded: false,
    })),
);

export const reducer = (state: TypeResourcesState | undefined, action: Action) => {
    return _reducer(state, action);
}
  
export const getTypeResources = (state: TypeResourcesState) => state.typeResources;
export const getTypeResourcesLoading = (state: TypeResourcesState) => state.loading;
export const getTypeResourcesLoaded = (state: TypeResourcesState) => state.loaded;