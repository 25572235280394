import { TiposRecursosClienteDto } from '@crc/sgr-typescript-client';
import { createAction, props } from '@ngrx/store';

export const loadTypeResourcesClient = createAction('[TiposRecursosClienteDto] Load TypeResourcesClient');

export const loadTypeResourcesClientSuccess = createAction(
  '[TiposRecursosClienteDto] Load TypeResourcesClient Success',
  props<{ typeResourceClient: TiposRecursosClienteDto[] }>()
);

export const loadTypeResourcesClientFailure = createAction(
  '[TiposRecursosClienteDto] Load TypeResourcesClient Failure',
  props<{ error: any }>()
);

export const clearTypeResourcesClient = createAction('[TiposRecursosClienteDto] Clear TypeResourcesClient');
