import { CaracteristicaDto } from '@crc/sgr-typescript-client';
import { createAction, props } from '@ngrx/store';

export const loadFeatures = createAction('[CaracteristicaDto] Load Features');

export const loadFeaturesSuccess = createAction(
  '[CaracteristicaDto] Load Features Success',
  props<{ features: CaracteristicaDto[] }>()
);

export const loadFeaturesFailure = createAction(
  '[CaracteristicaDto] Load Features Failure',
  props<{ error: any }>()
);

export const clearFeatures = createAction('[CaracteristicaDto] Clear Features');