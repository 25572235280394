import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FeaturesService } from '@shared/services/backend';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as featuresActions from '../actions/features.action';

@Injectable()
export class FeaturesEffects {

  getFeatures$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featuresActions.loadFeatures),
      switchMap(() => {
        return this.featuresService
          .getAllFeatures()
          .pipe(
            map((features) => {
              return featuresActions.loadFeaturesSuccess({ features })
            }),
            catchError(error => {
              return of(featuresActions.loadFeaturesFailure({error}))
            })
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private featuresService: FeaturesService,
  ) {}
}
