import { Injectable } from '@angular/core';
import { AppPrereservasService, CreatedItemResult, PrereservaAppCUDto, PrereservaAppDto} from '@crc/sgr-typescript-client';
import { PrebookingFilter } from '@shared/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrebookingService 
{
  constructor(private prereservasService : AppPrereservasService) { }

  getPrereservas(idTipoRecurso : number)
  {
    return this.prereservasService.apiAppPrereservaListIdTipoRecursoGet(idTipoRecurso);
  }

  getPrereservasByFilter(idTipoRecurso : number, filter : PrebookingFilter)
  {
    return this.prereservasService.apiAppPrereservaListIdTipoRecursoGet(idTipoRecurso, undefined, filter.fechaInicio, filter.fechaFin);
  }
  getPrereserva(id: number): Observable<PrereservaAppDto> 
  {
    return this.prereservasService.apiAppPrereservaIdIdPrereservaGet(id);
  }
  postPrereservas(datos: PrereservaAppCUDto): Observable<CreatedItemResult> 
  {
    return this.prereservasService.apiAppPrereservaPost(datos);
  }
  putPrereservas(datos: PrereservaAppCUDto): Observable<CreatedItemResult> 
  {
      return this.prereservasService.apiAppPrereservaPut(datos);
  }
  deletePrereserva(id:number) : Observable<string> 
  {
      return this.prereservasService.apiAppPrereservaIdDelete(id);
  }

  createPrereserva(datos: PrereservaAppCUDto): Observable<CreatedItemResult> 
  {
    return this.postPrereservas(datos);
  }
  editPrereserva(datos : PrereservaAppCUDto) : Observable<CreatedItemResult> {
    return this.putPrereservas(datos);
  }
}