import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';
import * as fromTypeResources from '../reducers/typeResources.reducer';

export const getTypeResourcesState = createSelector(
  fromFeature.getMainState,
  (state: fromFeature.MainState) => state.typeResources
);

export const getTypeResources = createSelector(
  getTypeResourcesState,
  fromTypeResources.getTypeResources
);

export const getTypeResourcesLoaded = createSelector(
  getTypeResourcesState,
  fromTypeResources.getTypeResourcesLoaded
);

export const getTypeResourcesLoading = createSelector(
  getTypeResourcesState,
  fromTypeResources.getTypeResourcesLoading
);

export const getTypeResourceById = (idTipoRecurso: number) => createSelector(
  getTypeResources,
  (typeResources) => {
    return typeResources.filter((typeResource) => typeResource.idTipoRecurso==idTipoRecurso)[0];
  }
)


