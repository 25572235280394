import { Injectable } from "@angular/core";
import { AppRecursosService, RecursoListDto } from "@crc/sgr-typescript-client";
import { IResourceFilter } from "@shared/models/local/i-resource-filter";
import { Observable } from "rxjs";
@Injectable({
    providedIn: 'root'
})
export class ResourcesService {
    constructor(private recurso: AppRecursosService) {}
    getResourcesToday(idTipoRecurso : number): Observable<RecursoListDto[]> {
        return this.recurso.apiAppRecursosLibresIdTipoRecursoGet(idTipoRecurso);
    }
    getResourcesAll(idTipoRecurso : number, resourceFilter: IResourceFilter) : Observable<RecursoListDto[]> {
        return this.recurso.apiAppRecursosFreeToPrebookingIdTipoRecursoGet(idTipoRecurso, resourceFilter.fechaInicio, resourceFilter.fechaFin);
    }

    getResourcesByFilter(idTipoRecurso: number, resourceFilter: IResourceFilter) : Observable<RecursoListDto[]> {
        return this.recurso.apiAppRecursosFreeToPrebookingIdTipoRecursoGet(idTipoRecurso, resourceFilter.fechaInicio, resourceFilter.fechaFin, resourceFilter.nombre, resourceFilter.idValores, resourceFilter.idPrerreservaIncluir);
    }
}