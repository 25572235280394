import { Injectable } from '@angular/core';
import { ResourceSelectorService } from '../front';
import { Store } from '@ngrx/store';
import * as fromStore from '@shared/store';
import { Observable, of } from 'rxjs';
import { TipoRecursoListDto, TiposRecursosClienteDto, TiposRecursosPersonaAppDto } from '@crc/sgr-typescript-client';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CurrentGrantsService {

  constructor(private store: Store<fromStore.MainState>, private rselector: ResourceSelectorService,) { }

  getConfByCurrentResource(): Observable<TiposRecursosClienteDto> {
    return new Observable<TiposRecursosClienteDto>(e => {
      this.rselector.getResource().subscribe({
        next: (selected: TipoRecursoListDto) => {
          this.store.select(fromStore.getTypeResourceClientById(selected.idTipoRecurso)).pipe(
            filter((data: TiposRecursosClienteDto) => data !== undefined)
          ).subscribe({
            next: (recursosPersona: TiposRecursosClienteDto) => {
              e.next(recursosPersona);
              e.complete()
            },
            error: err => e.error(err)
          });
        },
        error: err => e.error(err)
      })
    })
  }

  getByPersonaActual(): Observable<TiposRecursosPersonaAppDto> {
    return new Observable<TiposRecursosPersonaAppDto>(e => {
      this.rselector.getResource().subscribe({
        next: (selected: TipoRecursoListDto) => {
          this.store.select(fromStore.getTypeResourceGrantsById(selected.idTipoRecurso)).pipe(
            filter((data: TiposRecursosPersonaAppDto) => data !== undefined)
          ).subscribe({
            next: (recursosPersona: TiposRecursosPersonaAppDto) => {
              e.next(recursosPersona);
              e.complete()
            },
            error: err => e.error(err)
          });
        },
        error: err => e.error(err)
      });
    });
  }
  canNavigate(route: "inicio" | "solicitudes" | "prereservas" | "reservas" | "freetoday"): Observable<boolean> {
    if (route == "inicio" || route == "reservas") return of(true);
    return this.getByPersonaActual().pipe(
      map((x:TiposRecursosPersonaAppDto) => {
        return ((route == "solicitudes" && (x.nivelPermisoSolicitudes || 0) > 0) ||
          (route == "prereservas" && (x.nivelPermisoPrereservas || 0) > 0)) || (route == "freetoday" && (x.reservasEnDiaActual || 0) > 0)
      }
      ) 
    );
  }

}
