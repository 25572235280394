import { Component, OnInit } from '@angular/core';
import { PlanoRecursoAppDto, RecursoDto } from '@crc/sgr-typescript-client';
import { IPlano } from '@shared/models/local/i-plano';
import { BlobService } from '@shared/services/front';
import Projection from 'ol/proj/Projection';
import { Feature, Map, View } from 'ol';
import ImageLayer from 'ol/layer/Image';
import Static from 'ol/source/ImageStatic';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { getCenter } from 'ol/extent';
import { Polygon } from 'ol/geom';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import Text from 'ol/style/Text';
import { ModalController, NavParams } from '@ionic/angular';
@Component({
  selector: 'app-map-diagram',
  templateUrl: './map-diagram.component.html',
  styleUrls: ['./map-diagram.component.scss'],
})
export class MapDiagramComponent implements OnInit {
  protected map: Map = new Map({});
  private planos!: PlanoRecursoAppDto;
  private recurso!: RecursoDto;
  private plano!: IPlano;
  private extent: number[] = [0, 0, 0, 0];
  private projection = new Projection({
    code: 'xkccd-image',
    units: 'pixels',
    extent: this.extent
  });
  private source = new VectorSource({wrapX: false});
  private vector = new VectorLayer({
    source: this.source,
  });
  constructor(private modalCtrl: ModalController, private blobService: BlobService, private params : NavParams) {}

  ngOnInit() {
    this.planos = this.params.get("map");
    this.recurso = this.params.get("resource");
    this.plano = {
      ...this.planos, 
      fichero: this.blobService.b64toBlob(this.planos.fichero!)
    };
  }
  ionViewDidEnter() {
    this.loadPlanoMap(this.plano);
  }
  private loadPlanoMap(plano: IPlano): void {
    let selectedUrl = URL.createObjectURL(plano.fichero!);
    let selectedImg = new Image();
    selectedImg.onload = () => {
      this.extent = [0, 0, selectedImg.width, selectedImg.height];
      this.projection.setExtent(this.extent);
      this.map = new Map({
        layers:[
          new ImageLayer({
            source: new Static({
              url: selectedUrl,
              projection: this.projection,
              imageExtent: this.extent
            })
          }),
          this.vector
        ],
        target: 'map',
        view: new View({
          projection: this.projection,
          center: getCenter(this.extent),
          zoom: 6,
          extent: this.extent,
          zoomFactor: 1.2,
          minZoom: 6,
          maxZoom: 12,
          constrainOnlyCenter: true
        }),
      });
      if(this.recurso) {
        this.loadSavedItem(this.recurso);
      }
    }
    selectedImg.src = selectedUrl;
  }

  private loadSavedItem(itemLocationMapList: RecursoDto) {
    const features = new Array();


    const feature = this.createItemFeature(itemLocationMapList);
    features.push(feature);
    this.map.getView().fit(features[0].getGeometry(), {size: this.extent});
    this.source.addFeatures(features);
  }

  private createItemFeature(itemLocation: RecursoDto) {
    const feature = new Feature(new Polygon([[
      [itemLocation.ptoSupIzdaPosicionX!, itemLocation.ptoSupIzdaPosicionY!],
      [itemLocation.ptoSupIzdaPosicionX!,  itemLocation.ptoInfDchaPosicionY!],
      [itemLocation.ptoInfDchaPosicionX!, itemLocation.ptoInfDchaPosicionY!],
      [itemLocation.ptoInfDchaPosicionX!, itemLocation.ptoSupIzdaPosicionY!]]]));
    feature.setStyle(this.computeItemStyle(itemLocation));
    return feature;
  }

  private computeItemStyle(itemLocation: RecursoDto) {
    return new Style({
      stroke: new Stroke({
        width: 4,
        color: [125, 125, 125, 1]
      }),
      fill: new Fill({
        color: [150, 150, 255, 0.6]
      }),
      text: new Text({
        font: '12px helvetica,sans-serif',
        text: itemLocation? itemLocation.nombre : "",
        fill: new Fill({
          color: '#000'
        }),
        stroke: new Stroke({
          color: '#fff',
          width: 3
        })
      })
    });
  }
  cancel() {
    return this.modalCtrl.dismiss();
  }

}
