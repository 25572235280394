import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ILang } from '@shared/models';
import { Observable } from 'rxjs';
import * as dataEn from '../../../../assets/i18n/en.json';
import * as dataEs from '../../../../assets/i18n/es.json';
import { NgxInternalizationService } from './ngxInternalization.service';

@Injectable({
    providedIn: 'root'
})
export class LangService {

    constructor(public translate: TranslateService, public ngxInternalizationService: NgxInternalizationService) { }

    public get data(): any {
        if (this._data) {
            return JSON.parse(JSON.stringify(this._data));
        }
        return undefined;
    }
    protected get _data(): any {
        if (this.getCurrentLang()) {
            return this._langData[this.translate.currentLang];
        }
        return undefined;
    }
    private _langs: ILang[] = [
        {
            idIdioma: 2,
            codigo: "en"
        },
        {
            idIdioma: 1,
            codigo: "es"
        }
    ]
    private _langData: any = { // Object.key debe coincidir con _langs[x].codigo
        en: dataEn,
        es: dataEs
    };
    public changeLang(): Observable<any> {
        return new Observable<any>(e => {
            e.next(this.data);
            this.translate.onLangChange.subscribe({
                next: () => e.next(this.data)
            });
        });
    }

    public getLangs(): string[] {
        return this.translate.getLangs();
    }

    public getCurrentLang(): string {
        return this.translate.currentLang;
    }
    public get langs(): ILang[] {
        return this._langs;
    }

    public get currentLang(): ILang {
        return this.langs.find((x: ILang) => x.codigo == this.translate.currentLang)!;
    }
    public setLang(value: string) {
        this.translate.use(value);
    }

    public setRecordsObtained(field: string, valueX: any) {
        return this.ngxInternalizationService.NgxInterpolation(
            this.translate.instant(field),
            { value: valueX }
        );
    }
}
