import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NgxInternalizationService {
        
  NgxInterpolation: any = (translationObject: any, interpolationObject: {[k: string]: any}) => {
    switch(typeof translationObject){
        case "object":
            return Object.fromEntries(
                Object.entries(translationObject).map(
                ([k, v], i) => [k, this.NgxInterpolation(v, interpolationObject)]
                )
            );
        case "string":
            return translationObject.replace(
                /{{(\w+)}}/gm,
                (fullMatch, match) => interpolationObject[match]
            );
        default:
            return translationObject;
    }
}

}
