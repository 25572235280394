import { Injectable } from '@angular/core';
import { ClienteService } from '@crc/sgr-typescript-client';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private clienteService:ClienteService) { }

  getClientLogo(): Observable<any>{
    return this.clienteService.apiClienteLogoGet();
  }
}
