import { Injectable } from "@angular/core";
import { AppSolicitudService, CreatedItemResult, SolicitudAppCUDto, SolicitudAppDto } from "@crc/sgr-typescript-client";
import { IRequestFilter } from "@shared/models";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class RequestService {
    constructor(private solicitud: AppSolicitudService) {}

    getSolicitudes(tipoRecurso: number) : Observable<Array<SolicitudAppDto>>
    {
        return this.solicitud.apiAppSolicitudListIdTipoRecursoGet(tipoRecurso);
    }

    getSolicitudesByFilter(tipoRecurso: number, filter: IRequestFilter){
        return this.solicitud.apiAppSolicitudListIdTipoRecursoGet(tipoRecurso, filter.fechaInicio, filter.fechaFin);
    }

    getSolicitud(id: number): Observable<SolicitudAppDto> {
        return this.solicitud.apiAppSolicitudIdIdSolicitudGet(id);
    }
    postSolicitud(datos: SolicitudAppCUDto): Observable<CreatedItemResult> {
        return this.solicitud.apiAppSolicitudPost(datos);
    }
    putSolicitud(datos: SolicitudAppCUDto): Observable<CreatedItemResult> {
        return this.solicitud.apiAppSolicitudPut(datos);
    }
    deleteSolicitud(id:number) : Observable<string> {
        return this.solicitud.apiAppSolicitudIdDelete(id);
    }
    createEditSolicitud(datos: SolicitudAppCUDto): Observable<CreatedItemResult> {
        if(datos.idSolicitud == 0) return this.postSolicitud(datos);
        return this.putSolicitud(datos);
    }
}
