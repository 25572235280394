import { TiposRecursosPersonaAppDto } from "@crc/sgr-typescript-client";
import * as fromTypeResourcesGrants from '../actions/typeResourceGrants.action';
import { Action, createReducer, on } from '@ngrx/store';

export interface TypeResourcesGrantsState {
    typeResourcesGrants: TiposRecursosPersonaAppDto[];
    loaded: boolean;
    loading: boolean;
}

export const initialState: TypeResourcesGrantsState = {
    typeResourcesGrants: [],
    loaded: false,
    loading: false,
};

const _reducer = createReducer(
    initialState,
    on(fromTypeResourcesGrants.loadTypeResourcesGrants, state => ({
        ...state,
        loading: true
    })),
    on(
        fromTypeResourcesGrants.loadTypeResourcesGrantsSuccess,
        (state, { typeResourcesGrants }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            typeResourcesGrants,
        };
    }),
    on(fromTypeResourcesGrants.loadTypeResourcesGrantsFailure, state => ({
        ...state,
        loading: false,
        loaded: false,
    })),
);

export const reducer = (state: TypeResourcesGrantsState | undefined, action: Action) => {
    return _reducer(state, action);
}
  
export const getTypeResourcesGrants = (state: TypeResourcesGrantsState) => state.typeResourcesGrants;
export const getTypeResourcesGrantsLoading = (state: TypeResourcesGrantsState) => state.loading;
export const getTypeResourcesGrantsLoaded = (state: TypeResourcesGrantsState) => state.loaded;