import { Component, EventEmitter, Input, Output} from '@angular/core';
import { ActionEnum } from '@shared/enums';
import { ResourceToPage } from '@shared/models/local/resource-to-page';

@Component({
  selector: 'app-subheader',
  templateUrl: './subheader.component.html',
})
export class SubheaderComponent
{
  @Input() buttons : any;

  @Output() selectedOption: EventEmitter<ResourceToPage> = new EventEmitter<ResourceToPage>();
  protected valor!:ResourceToPage;

  pressedInsert()
  {   
    this.valor={estado:ActionEnum.Insert};
    this.selectedOption.emit(this.valor);
  }

  pressedSearch()
  {   
    this.valor = {estado:ActionEnum.Search};
    this.selectedOption.emit(this.valor);
  }
}