import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateUtilsService {
  toDate(fecha: Date | string): Date  {
    return new Date(fecha);
  }
  dateToGMT(fecha: Date | string) {
    let f: Date = this.toDate(fecha);
    let gmt = new Date(f.toLocaleString("en-US", {timeZone: "GMT"}));
    let offset = f.getTime()-gmt.getTime();
    return new Date(f.getTime()+offset);
  }
  resetHours(fecha: Date | string) {
    let f: Date = this.toDate(fecha);
    f.setHours(0);
    f.setMinutes(0);
    f.setSeconds(0);
    f.setMilliseconds(0);
    return f;
  }
  dateToGMTNoTime(fecha: Date | string) {
    return this.dateToGMT(this.resetHours(fecha));
  }
  compare(f1: Date | string, f2: Date | string): number {
    /*
    <0: f1<f2
    =0: f1=f2
    >0: f1>f2
    */
    let fecha1: Date = this.toDate(f1);
    let fecha2: Date = this.toDate(f2);
    return fecha1.getTime()-fecha2.getTime();
  }

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
  
    return date;
  }
}
