import {
    Params,
  } from '@angular/router';
  import { createFeatureSelector, ActionReducerMap, MetaReducer } from '@ngrx/store';
  
  import * as fromSpinner from './spinner.reducer';
  
  import { Injectable } from "@angular/core";
  
  export interface RouterStateUrl {
    url: string;
    queryParams: Params;
    params: Params;
  }
  
  export interface State {
    spinner: fromSpinner.SpinnerState;
  }
  
  export const reducers: ActionReducerMap<State> = {
    spinner: fromSpinner.reducer,
  };
  
  export const metaReducers: MetaReducer<State>[] = [];
  
  export const getSpinnerState = createFeatureSelector<
    fromSpinner.SpinnerState
    >('spinner');
  
  @Injectable()
  export class CustomSerializer {
    
  }
  