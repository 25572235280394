import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TypeResourcesService } from '@shared/services/backend';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as typeResorucesActions from '../actions/typeResources.action';



@Injectable()
export class TypeResourcesEffects {

  getTypeResources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(typeResorucesActions.loadTypeResources),
      switchMap(() => {
        return this.typeResourceService
          .get()
          .pipe(
            map((typeResources) => {
              return typeResorucesActions.loadTypeResourcesSuccess({ typeResources })
            }),
            catchError(error => {
              return of(typeResorucesActions.loadTypeResourcesFailure({error}))
            })
          );
      })
    )
  );
  
  constructor(
    private actions$: Actions,
    private typeResourceService: TypeResourcesService
  ) {}
}
