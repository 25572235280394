import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DateRange } from '@angular/material/datepicker';
import { DateUtilsService } from '@shared/services/front/date-utils.service';

@Component({
  selector: 'app-dates-form',
  templateUrl: './dates-form.component.html',
  styleUrls: ['./dates-form.component.scss']
})
export class DatesFormComponent implements OnInit {
  @Input() selectedRangeValue: DateRange<Date> | undefined;
  @Input() datesFG!: FormGroup;
  @Input() maxDaysForRequest! : number;
  @Input() minDateSW : boolean = false;
  @Output() selectedRangeValueChange = new EventEmitter<DateRange<Date>>();
  protected minDate! : Date;
  protected maxDay! : Date;

  constructor(private dateUtils: DateUtilsService) {}
  ngOnInit() {
    if(!this.selectedRangeValue){
      this.selectedRangeValue = new DateRange<Date>(null, null);
    }
    this.datesFG.addValidators(this.dateValidator)
    if(this.maxDaysForRequest && this.maxDaysForRequest != 0){
      this.maxDay = this.dateUtils.addDays(new Date(), this.maxDaysForRequest+1);
    }
    if(this.minDateSW) this.minDate = this.dateUtils.addDays(new Date(), 1);
    if(this.selectedRangeValue) this.selectedChange(this.selectedRangeValue.end || this.selectedRangeValue.start!);
  }

  selectedChange(m: Date) 
  {
    if(!m) return;
    let convertido= this.dateUtils.dateToGMTNoTime(m);
    if(this.selectedRangeValue?.end || !this.selectedRangeValue?.start){
      this.selectedRangeValue = new DateRange<Date>(convertido, null);
    }else if(this.selectedRangeValue?.start && this.selectedRangeValue.start > convertido){
      this.selectedRangeValue = new DateRange<Date>(convertido, this.selectedRangeValue.start);
    } else {
      this.selectedRangeValue = new DateRange<Date>(this.selectedRangeValue.start, convertido);
    }
    this.datesFG.patchValue({ fechaInicio: this.selectedRangeValue.start, fechaFin: this.selectedRangeValue.end });
    if(this.isValid){
      this.selectedRangeValueChange.emit(this.selectedRangeValue);
    }
}

  public get isValid(): boolean {
    return this.datesFG && !this.datesFG.controls.fechaInicio.invalid && !this.datesFG.controls.fechaFin.invalid;
  };
  private dateValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null=> {
    const start = control.get('fechaInicio')?.value;
    const end = control.get('fechaFin')?.value;
    return start && end && this.dateUtils.compare(start, end) > 0 ? {dateValid:true} : null;
  }
  
  errors(type: 'fechaInicio' | 'fechaFin'): boolean {
    return (!this.datesFG.controls[type].valid) || (this.datesFG.errors && this.datesFG.errors.dateValid)
  }
}