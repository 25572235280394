import { Action, createReducer, on } from '@ngrx/store';

import * as fromSpinner from '../actions/spinner.action';

export interface SpinnerState {
  showCount: number;
}

export const initialState: SpinnerState = {
  showCount: 0
};

const _reducer = createReducer(
  initialState,
  on(fromSpinner.hideSpinner, state => ({ ...state, showCount: state.showCount - 1 })),
  on(fromSpinner.showSpinner, state => ({ ...state, showCount: state.showCount + 1 }))
);

export function reducer(state: SpinnerState | undefined, action: Action) {
  return _reducer(state, action);
}

export const isShowing = (state: SpinnerState) => state.showCount > 0;
