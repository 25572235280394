import { Injectable } from '@angular/core';
import { CaracteristicaDto, CaracteristicaListDto, CaracteristicaService, CreatedItemResult, TiposRecursoService } from '@crc/sgr-typescript-client';
import { IFeaturesFilter } from '@shared/models/local/i-features-filter';
import { Observable } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class FeaturesService {
    constructor(private caracteristicas: CaracteristicaService, private tiposRecurso: TiposRecursoService) { }
    getFeatureById(caracteristicaId: number): Observable<CaracteristicaDto> {
        return this.caracteristicas.apiCaracteristicaIdGet(caracteristicaId);
    }
    getFeatureByResource(resource: number): Observable<CaracteristicaDto[]> {
        return this.tiposRecurso.apiTiposRecursoIdTipoRecursoCaracteristicasGet(resource);
    }
    getFeatureByFilter(featuresFilter: IFeaturesFilter): Observable<CaracteristicaListDto[]> {
        return this.caracteristicas.apiCaracteristicaGet(
            featuresFilter.typeFeaturesId,
            featuresFilter.name,
            featuresFilter.orderBy,
            featuresFilter.orderAsc,
            featuresFilter.range,
        );
    }

    getAllFeatures(){
        return this.caracteristicas.apiCaracteristicaAllGet();
    }

    insert(caracteristicaDTO: CaracteristicaDto): Observable<CreatedItemResult> {
        return this.caracteristicas.apiCaracteristicaPost(caracteristicaDTO);
    }

    update(caracteristicaDTO: CaracteristicaDto): Observable<CreatedItemResult> {
        return this.caracteristicas.apiCaracteristicaPut(caracteristicaDTO);
    }

    delete(caracteristicaId: number) {
        return this.caracteristicas.apiCaracteristicaIdDelete(caracteristicaId);
    }
}