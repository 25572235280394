import { Injectable } from '@angular/core';
import { TiposRecursosPersonaAppDto } from '@crc/sgr-typescript-client';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TypeResourcesService } from '@shared/services/backend';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as typeResorucesGrantsActions from '../actions/typeResourceGrants.action';

@Injectable()
export class TypeResourcesGrantsEffects {

  getTypeResourcesGrants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(typeResorucesGrantsActions.loadTypeResourcesGrants),
      switchMap(() => {
        return this.typeResourceService
          .getPersona()
          .pipe(
            map((typeResourcesGrants : TiposRecursosPersonaAppDto[]) => {
              return typeResorucesGrantsActions.loadTypeResourcesGrantsSuccess({ typeResourcesGrants })
            }),
            catchError(error => {
              return of(typeResorucesGrantsActions.loadTypeResourcesGrantsFailure({error}))
            })
          );
      })
    )
  );
  
  constructor(
    private actions$: Actions,
    private typeResourceService: TypeResourcesService
  ) {}
}
