import { Injectable } from '@angular/core';
import { AppPermisosService, CreatedItemResult, TipoRecursoListDto, TiposRecursosClienteDto, TiposRecursosPersonaAppDto } from '@crc/sgr-typescript-client';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class TypeResourcesService {

  constructor(private recurso: AppPermisosService) { }

  get(): Observable<TipoRecursoListDto[]> { 
      return this.recurso.apiAppTiposRecursosGet();
  }
  getPersona() : Observable<TiposRecursosPersonaAppDto[]>{
    return this.recurso.apiAppTiposRecursoPersonaGet();
  }
  getConfCliente() : Observable<TiposRecursosClienteDto[]>{
    return this.recurso.apiAppConfClienteGet();
  }
  updateConf() : Observable<CreatedItemResult>{
     return this.recurso.apiAppTiposRecursoConfiguracionPut();
  }
}