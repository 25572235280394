import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { LangService, NoNetworkService, ToastUtilsService } from '@shared/services/front';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  protected dataJSON!: any;
  constructor(private toastrService: ToastUtilsService, private router: Router, private langService: LangService, 
    private noNetworkService: NoNetworkService, private authService: AuthService) {
    this.langService.changeLang().subscribe({
      next: (data: any) => {
        this.dataJSON = data;
      }
    });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError(error => this.errorHandler(error)));
  }

  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {

    if (response instanceof HttpErrorResponse) {
      if (response && response.error != null) {

        if (response.status == 0) {
          this.noNetworkService.show();
          throw response;
        }
        const error = this.dataJSON.ERROR[response.status.toString()].find((item: { hasOwnProperty: (arg0: any) => any; }) => item.hasOwnProperty(response.error.ErrorCode.toString()));
        const errorMessage = error ? error[response.error.ErrorCode.toString()] : this.dataJSON.ERROR[response.status.toString()][0].default;
        this.toastrService.presentToast({ color: 'danger', message: errorMessage, duration: 2000 });
        if (response.status == 401) {
          localStorage.removeItem("access_token");
          this.router.navigate(['/']);
          this.authService.logout();
        }
      } else {
        this.authService.isAuthenticated$ = of(false);
        this.router.navigate(['/']);
        this.authService.logout();
      }
    }
    throw response;
  }
}