import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionEnum } from '@shared/enums';
import { ButtonType } from '@shared/models';
import { ResourceToPage } from '@shared/models/local/resource-to-page';
import { ResourceData } from '../../models/local/resource-data';

@Component({
  selector: 'app-resource',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.scss'],
})
export class ResourceComponent {

  @Input() resource!: ResourceData;
  @Output() buttonListener: EventEmitter<ResourceToPage> = new EventEmitter<ResourceToPage>();
  protected mapAction: ActionEnum = ActionEnum.ShowMap;
  protected get isSmallScreen(): boolean {
    return window.innerWidth < 400;
  }
  protected get hasMap(): boolean {
    return (this.resource?.buttons?.includes("map") || false) && !!this.resource.map;
  }
  protected valor!:ResourceToPage;
  protected colors = ["success", "warning", null];
  protected buttonTypes: ButtonType[] = [
    {type: "map", estado: ActionEnum.ShowMap, icon: "map", position: 'center'},
    {type: "modify",  estado: ActionEnum.Modify, icon: "pencil-outline", position: 'end' },
    {type: "delete", estado: ActionEnum.Delete, icon: "close-outline", position: 'end'},
    {type: "book", estado: ActionEnum.Insert, icon: "add-outline", position: 'end' }
  ];
  protected get featuresLength(): number {
    return this.resource.features.filter(x => x.value).length;
  };
  
  pressed(estado: ActionEnum): void {
    this.buttonListener.emit({estado, data: this.resource.uid});
  }
}