import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';
import * as fromTypeResourcesClient from '../reducers/typeResourceClient.reducer';

export const getTypeResourcesClientState = createSelector(
  fromFeature.getMainState,
  (state: fromFeature.MainState) => state.typeResourceClient
);

export const getTypeResourcesClient = createSelector(
  getTypeResourcesClientState,
  fromTypeResourcesClient.getTypeResourcesClient
);

export const getTypeResourcesClientLoaded = createSelector(
  getTypeResourcesClientState,
  fromTypeResourcesClient.getTypeResourcesClientLoaded
);

export const getTypeResourcesClientLoading = createSelector(
  getTypeResourcesClientState,
  fromTypeResourcesClient.getTypeResourcesClientLoading
);

export const getTypeResourceClientById = (idTipoRecurso: number) => createSelector(
  getTypeResourcesClient,
  (typeResources) => {
    return typeResources.filter((typeResource) => typeResource.idTipoRecurso==idTipoRecurso)[0];
  }
)


