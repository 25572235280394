import { CaracteristicaDto } from "@crc/sgr-typescript-client";
import * as fromFeatures from '../actions/features.action';
import { Action, createReducer, on } from '@ngrx/store';

export interface FeaturesState {
    features: CaracteristicaDto[];
    loaded: boolean;
    loading: boolean;
}

export const initialState: FeaturesState = {
    features: [],
    loaded: false,
    loading: false,
};

const _reducer = createReducer(
    initialState,
    on(fromFeatures.loadFeatures, state => ({
        ...state,
        loading: true
    })),
    on(
        fromFeatures.loadFeaturesSuccess,
        (state, { features }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            features,
        };
    }),
    on(fromFeatures.loadFeaturesFailure, state => ({
        ...state,
        loading: false,
        loaded: false,
    })),
);

export const reducer = (state: FeaturesState | undefined, action: Action) => {
    return _reducer(state, action);
}
  
export const getFeatures = (state: FeaturesState) => state.features;
export const getFeaturesLoading = (state: FeaturesState) => state.loading;
export const getFeaturesLoaded = (state: FeaturesState) => state.loaded;