import { Component } from '@angular/core';
import { LoaderService, NoNetworkService } from '@shared/services/front';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  protected isLoading: Subject<boolean> = this.loaderService.isLoading;
  protected connectionError: Subject<boolean> = this.noNetworkService.connectionError;
  constructor(private loaderService: LoaderService, private noNetworkService: NoNetworkService) { }
  retry() {
    window.location.reload();
  }
}
