import { TipoRecursoListDto } from '@crc/sgr-typescript-client';
import { createAction, props } from '@ngrx/store';

export const loadTypeResources = createAction('[TipoRecursoListDto] Load TypeResources');

export const loadTypeResourcesSuccess = createAction(
  '[TipoRecursoListDto] Load TypeResources Success',
  props<{ typeResources: TipoRecursoListDto[] }>()
);

export const loadTypeResourcesFailure = createAction(
  '[TipoRecursoListDto] Load TypeResources Failure',
  props<{ error: any }>()
);

export const clearTypeResources = createAction('[TipoRecursoListDto] Clear TypeResources');
