import { Injectable } from '@angular/core';
import { TipoRecursoListDto } from '@crc/sgr-typescript-client';
import { BehaviorSubject, Observable, Subscriber, of } from 'rxjs';
import { filter } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ResourceSelectorService {
  public canEdit: boolean = true;
  public get hasResource(): boolean {
    return !!ResourceSelectorService.resource;
  }
  private static resource?: TipoRecursoListDto;
  private subject: BehaviorSubject<TipoRecursoListDto | null> = new BehaviorSubject<TipoRecursoListDto | null>(null);
  private subject$: Observable<TipoRecursoListDto> = this.subject.asObservable().pipe(filter((val: TipoRecursoListDto | null) => {return val != null})) as Observable<TipoRecursoListDto>;
  setResource(resource: TipoRecursoListDto) : void {
    if (resource?.idTipoRecurso == ResourceSelectorService.resource?.idTipoRecurso) return;
    this.subject.next(resource);
  }
  getResource(): Observable<TipoRecursoListDto> {
    if(ResourceSelectorService.resource) return of(ResourceSelectorService.resource);
    return new Observable<TipoRecursoListDto>((e: Subscriber<TipoRecursoListDto>) => {
      this.subject$.subscribe({
        next: (d: TipoRecursoListDto) => {
          e.next(d);
          e.complete();
        },
        error: e.error
      });
    });
  }
  onChange(): Observable<TipoRecursoListDto> {
    return this.subject$;
  }
}
