import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NoNetworkService {
  connectionError = new Subject<boolean>();
  show() {
    setTimeout(()=> this.connectionError.next(true), 0)
  }
  hide() {
    setTimeout(()=> this.connectionError.next(false), 0)
  }
}
