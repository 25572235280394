import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromFeatures from './features.reducer';
import * as fromTypeResources from './typeResources.reducer';
import * as fromGrants from './typeResourceGrants.reducer';
import * as fromClient from './typeResourceClient.reducer';

export interface MainState {
    typeResources: fromTypeResources.TypeResourcesState;
    features: fromFeatures.FeaturesState;
    typeResourceGrants: fromGrants.TypeResourcesGrantsState;
    typeResourceClient: fromClient.TypeResourcesClientState;
}

export const reducers: ActionReducerMap<MainState> = {
    typeResources: fromTypeResources.reducer,
    features: fromFeatures.reducer,
    typeResourceGrants: fromGrants.reducer,
    typeResourceClient: fromClient.reducer
};

export const getMainState = createFeatureSelector<MainState>(
    'main'
);