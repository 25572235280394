import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import {  PersonaAppRUDto, TipoRecursoListDto, TiposRecursosPersonaAppDto } from '@crc/sgr-typescript-client';
import { IonMenu, NavController } from '@ionic/angular';
import { BlobService, CurrentGrantsService, LangService, ResourceSelectorService } from '@shared/services/front';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromStore from '@shared/store';
import { filter } from 'rxjs/operators';
import { IMenuPages } from '@shared/models';
import { SideMenuEnum } from '@shared/enums';
import { AuthService } from 'src/app/auth/auth.service';
import { PersonService } from '@shared/services/backend';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
})
export class SidemenuComponent implements OnInit {
  @Input() elements!: IMenuPages[];
  @Input() logo$! : Observable<string>;
  public appPages: IMenuPages[] = [];
  protected mySrc! : SafeUrl;
  protected datoAcceso?: string;
  @ViewChild("mainMenu") mainMenu!: IonMenu;
  image!: string;
  headerMenuItem = {
    'background': '/assets/background-banner3.jpg',
    'logo': '/assets/icon/logo/logo.png',
    'title': ""
  };
  constructor(
    private person:PersonService, 
    private store: Store<fromStore.MainState>, 
    private authService : AuthService, 
    public langService: LangService, 
    private navController: NavController, 
    private rs: ResourceSelectorService, 
    private blobService : BlobService, 
    private currentGrantService : CurrentGrantsService,
    private router: Router
    ) {}
  openPage(page: string) {
    this.mainMenu.close();
    this.navController.navigateRoot([page], {});
  }
  ngOnInit(): void {
    this.langService.changeLang().subscribe({
			next: (data : any) => {
        this.getDataJSON(JSON.parse(JSON.stringify(data)));
        
			}
		});
    this.store.select(fromStore.getTypeResources).pipe(
      filter(data => data !== null && data.length > 0)
    ).subscribe({
      next: (x: TipoRecursoListDto[])  => {
        if(!this.rs.hasResource){
          this.rs.setResource(x[0]);
          this.reloadResource(x[0]);
        }
      }
    });
    this.rs.onChange().subscribe({
      next: (resource: TipoRecursoListDto) => {
        this.reloadResource(resource);
        this.changesPermission();
      },
      error: (e) => {
      },
    });
    this.rs.getResource().subscribe({
      next: (resource: TipoRecursoListDto) => {
        this.reloadResource(resource);
      },
      error: (e) => {
      },
    });
    this.logo$.subscribe({
      next: (x:string) => {
        let logo = this.blobService.b64toBlob(x);
        const reader = new FileReader();
        reader.readAsDataURL(logo);
        reader.onloadend = () => {
          this.mySrc = reader.result?.toString()!;
        };
      }
    }) 
  }

  reloadResource(recurso: TipoRecursoListDto){
    this.headerMenuItem.title = recurso.nombre!;
  }

  protected changeLanguage(value:any){
    this.langService.setLang(value);
    this.langService.changeLang();
    let idioma = this.langService.langs.find(x => x.codigo == (value ?? "en") || `${x.idIdioma}` == `${value}` );
    let configuration:Observable<PersonaAppRUDto> =this.person.getLang();

    configuration.subscribe({
      next: (x: PersonaAppRUDto) => {
        x.idIdioma=idioma?.idIdioma;
        this.person.putLang(x).subscribe();
      }
    })
  }

  logout(){
    this.authService.logout();
    this.router.navigate(['']);
    localStorage.clear();
  }

  protected getDataJSON(dataJSON:any){
    this.appPages = [
      { title: dataJSON.TABS.Home, url: '/dashboard', icon: 'home', type: SideMenuEnum.Home },
      { title: dataJSON.TABS.Requests, url: '/dashboard/request', icon: 'document', type: SideMenuEnum.Requests },
      { title: dataJSON.TABS.PreBookings, url: '/dashboard/pre-booking', icon: 'document-text', type: SideMenuEnum.PreBookings },
      { title: dataJSON.TABS.Bookings, url: '/dashboard/booking', icon: 'flag', type: SideMenuEnum.Bookings },
      { title: dataJSON.FREETODAY.AvailableCurrentRes, url: '/dashboard/free-today', icon: 'lock-open', resalt: true, type: SideMenuEnum.FreeToday },
      { title: dataJSON.TABS.Identifiers, url: "/dashboard/identifier-list", icon: 'key', type: SideMenuEnum.Identifiers, permission: false },
      { title: dataJSON.TABS.Conf, url:'/dashboard/settings', icon: 'settings', type: SideMenuEnum.Settings },
      { title: dataJSON.TABS.LogOut, url: '/logout', icon: 'log-out', type:SideMenuEnum.LogOut },
    ];
    this.changesPermission();
  }
  changesPermission(){
    this.currentGrantService.getByPersonaActual().subscribe({
      next: (x: TiposRecursosPersonaAppDto) => {
        this.appPages[this.appPages.findIndex(x => x.type == SideMenuEnum.Requests)].permission = (x.nivelPermisoSolicitudes || 0) > 0;
        this.appPages[this.appPages.findIndex(x => x.type == SideMenuEnum.PreBookings)].permission = (x.nivelPermisoPrereservas || 0) > 0;
        this.appPages[this.appPages.findIndex(x => x.type == SideMenuEnum.FreeToday)].permission = (x.reservasEnDiaActual || 0) > 0;
      }
    });
    this.rs.getResource().subscribe({
      next: resource => {
        this.store.select(fromStore.getTypeResourceClientById(resource.idTipoRecurso)).pipe(filter(x => !!x)).subscribe({
          next: resourceData => {
            this.appPages[this.appPages.findIndex(x => x.type == SideMenuEnum.Identifiers)].permission = !!resourceData.datoAcceso;
          }
        })
      }
    });
  }
}