import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
  isAuthenticated$: Observable<boolean>;

  constructor(private authService: AuthService, private router: Router) {
    this.isAuthenticated$ = authService.isAuthenticated$;
  }

  login() {
    this.authService.login();
  }
    
  ngOnInit() {
    this.isAuthenticated$.subscribe((value:boolean) =>{
      if(value && this.authService.hasValidToken()){
        this.router.navigate(["/dashboard"]);
      }
  });
  }
}
