import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';
import * as fromTypeResourcesGrants from '../reducers/typeResourceGrants.reducer';

export const getTypeResourcesGrantsState = createSelector(
  fromFeature.getMainState,
  (state: fromFeature.MainState) => state.typeResourceGrants
);

export const getTypeResourcesGrants = createSelector(
  getTypeResourcesGrantsState,
  fromTypeResourcesGrants.getTypeResourcesGrants
);

export const getTypeResourcesGrantsLoaded = createSelector(
  getTypeResourcesGrantsState,
  fromTypeResourcesGrants.getTypeResourcesGrantsLoaded
);

export const getTypeResourcesGrantsLoading = createSelector(
  getTypeResourcesGrantsState,
  fromTypeResourcesGrants.getTypeResourcesGrantsLoading
);

export const getTypeResourceGrantsById = (idTipoRecurso: number) => createSelector(
  getTypeResourcesGrants,
  (typeResources) => {
    return typeResources.filter((typeResource) => typeResource.idTipoRecurso==idTipoRecurso)[0];
  }
)


