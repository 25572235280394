import { CaracteristicaDto } from '@crc/sgr-typescript-client';
import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';
import * as fromFeatures from '../reducers/features.reducer';

export const getFeaturesState = createSelector(
  fromFeature.getMainState,
  (state: fromFeature.MainState) => state.features
);

export const getFeatures = createSelector(
  getFeaturesState,
  fromFeatures.getFeatures
);

export const getFeaturesLoaded = createSelector(
  getFeaturesState,
  fromFeatures.getFeaturesLoaded
);

export const getFeaturesLoading = createSelector(
  getFeaturesState,
  fromFeatures.getFeaturesLoading
);

export const getFeaturesByIdTypeResource = (idTipoRecurso: number) => createSelector(
    getFeatures,
    (features) => {
      if(idTipoRecurso){
        return features.filter((feature : CaracteristicaDto) => feature.idTipoRecurso == idTipoRecurso);
      } else {
        return features;
      }
    }
  )